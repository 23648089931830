import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import qs from 'qs'

const baseUrl = process.env.VUE_APP_BASE_URL

const customAxios = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': process.env.VUE_APP_COMMON_ACCESS_KEY
  }
})

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    request_client_user: JSON.parse(localStorage.getItem("smp-request-app-user")),
  },
  getters: {
    isAuthenticated: state => !!state.request_client_user,
    isAdminUser: state => !!state.request_client_user && state.request_client_user.role == "admin",
    isNomalUser: state => !!state.request_client_user && state.request_client_user.role == "nomal",
    isSendaiUser: state => !!state.request_client_user && (state.request_client_user.role == "sendai_airport" || state.request_client_user.role == "sendai_information" || state.request_client_user.role == "sendai_operator"),
    isSendaiAdmin: state => !!state.request_client_user && state.request_client_user.role == "sendai_airport",
    isSendaiInformation: state => !!state.request_client_user && state.request_client_user.role == "sendai_information",
    isAeonMakuhariInformation: state => !!state.request_client_user && state.request_client_user.role == "aeon_makuhari_information",
    isAeonMakuhariAdmin: state => !!state.request_client_user && state.request_client_user.role == "aeon_makuhari",
    isAeonMakuhariUser: state => !!state.request_client_user && (state.request_client_user.role == "aeon_makuhari_information" || state.request_client_user.role == "aeon_makuhari"),
    isSendaiOperator: state => !!state.request_client_user && state.request_client_user.role == "sendai_operator",
    isRakeTownAdmin: state => !!state.request_client_user && state.request_client_user.role == "rake_town",
    isAeonMakuhariAccessible: state => !!state.request_client_user && state.request_client_user.role == "aeon_makuhari_accessible",
    isSetStoreAddress: state => state.request_client_user.role == "admin" || state.request_client_user.is_set_store_address,
    isDeletionRightHolder: state => state.request_client_user.role != "admin" && state.request_client_user.is_deletion_right_holder,
    isGateControlStore: state => state.request_client_user.role == "admin" || state.request_client_user.is_gate_control,
    isAlertNotificationFlagged: state => (state.request_client_user.role == "admin") || state.request_client_user.is_alert_notification_flagged,
    isAccessibleVehicleFlagged: state => (state.request_client_user.role == "admin") || state.request_client_user.is_accessible_vehicle_flagged,
    requestClientUserId: state => state.request_client_user.id,
    userRequestClientStoreId: state => state.request_client_user.request_client_store_id,
    requestClientStores: state => state.request_client_user.request_client_stores,
    productId: state => state.request_client_user.request_client_product_id,
    productName: state => state.request_client_user.product_name
  },
  mutations: {
    setUser(state, request_client_user) {
      state.request_client_user = request_client_user
      localStorage.setItem("smp-request-app-user", JSON.stringify(request_client_user))
    },
    clearUser(state) {
      state.request_client_user = null
      localStorage.removeItem("smp-request-app-user")
    },
  },
  actions: {
    async signIn({ commit }, { email, password }) {
      const url = `session`
      const data = {
        email: email,
        password: password,
        userId: 1
      }
      try {
        const response = await customAxios.post(url, qs.stringify(data))
        commit("setUser", response.data)
      } catch (error) {
        console.error('Error signing in:', error)
        throw error
      }
    },
    signOut({ commit }) {
      const url    = baseUrl + "session"
      return axios.delete(url)
      .then(() => {
        commit("clearUser")
      })
    },
    async fetchUserStatus({ commit }) {
      const url = `${baseUrl}session`
      return axios.get(url)
      .then(res => {
        commit("setUser", res.data)
      })
    }
  }
})
