<template>
  <v-container>
    <BrowserVersionAlert/>
    <ReleaseNote/>
    <v-card class="my-2">
      <v-card-title class="text-h6">依頼種別を選択</v-card-title>
      <v-list nav dense> 
        <v-list-item v-if="$store.getters.isAdminUser || $store.getters.isSendaiAdmin" link to="/dashboard">
          <v-list-item-title class="text-caption">ダッシュボード</v-list-item-title>
        </v-list-item>
        <v-list-group :value="true" no-action sub-group v-if="$store.getters.isAdminUser || $store.getters.isNomalUser || $store.getters.isSendaiAdmin || $store.getters.isSendaiInformation || $store.getters.isAeonMakuhariAdmin">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>駐車料金請求取消依頼</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link dense to="/new-delete-request">
            <v-list-item-title>申請</v-list-item-title>
          </v-list-item>
          <v-list-item link dense to="/delete-request-histories">
            <v-list-item-title>申請状況一覧</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group :value="true" no-action sub-group v-if="$store.getters.isAdminUser || $store.getters.isNomalUser || $store.getters.isSendaiAdmin || $store.getters.isSendaiInformation || $store.getters.isAeonMakuhariAdmin">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>精算不要車両</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isAuthenticated && $store.getters.isSetStoreAddress" link dense to="/new-no-payment-required-vehicle">
            <v-list-item-title>登録</v-list-item-title>
          </v-list-item>
          <v-list-item v-else disabled link dense to="/new-no-payment-required-vehicle">
            <v-list-item-title>登録（準備中）</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$store.getters.isAuthenticated && $store.getters.isSetStoreAddress" link dense to="/no-payment-required-vehicles">
            <v-list-item-title>登録済車両一覧</v-list-item-title>
          </v-list-item>
          <v-list-item v-else disabled link dense to="/no-payment-required-vehicles">
            <v-list-item-title>登録済車両一覧（準備中）</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$store.getters.isAuthenticated && $store.getters.isSetStoreAddress" link dense to="/output-no-payment-required-vehicle">
            <v-list-item-title>CSV出力</v-list-item-title>
          </v-list-item>
          <v-list-item v-else disabled link dense to="/output-no-payment-required-vehicles">
            <v-list-item-title>CSV出力（準備中）</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group :value="true" no-action sub-group class="pb-5" v-if="$store.getters.isAdminUser || $store.getters.isNomalUser || $store.getters.isSendaiAdmin || $store.getters.isSendaiInformation || $store.getters.isAeonMakuhariAdmin">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>アラート通知車両</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isAuthenticated && $store.getters.isSetStoreAddress" link dense to="/new-alert-vehicles">
            <v-list-item-title>登録</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$store.getters.isAuthenticated && $store.getters.isSetStoreAddress" link dense to="/alert-vehicles">
            <v-list-item-title>登録済車両一覧</v-list-item-title>
          </v-list-item>
          <v-list-item v-else link dense disabled to="/alert-vehicles">
            <v-list-item-title>登録済車両一覧(準備中)
            </v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="true" no-action sub-group class="pb-5" v-if="$store.getters.isAdminUser || (($store.getters.isNomalUser || $store.getters.isAeonMakuhariAdmin || $store.getters.isSendaiAdmin || $store.getters.isSendaiInformation || $store.getters.isAeonMakuhariAccessible) && $store.getters.isAlertNotificationFlagged)">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>入場許可車両</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isAuthenticated && $store.getters.isSetStoreAddress" link dense to="/new-gate-control-vehicles">
            <v-list-item-title>登録</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$store.getters.isAuthenticated && $store.getters.isSetStoreAddress" link dense to="/gate-control-vehicles">
            <v-list-item-title>登録済車両一覧</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group :value="true" no-action sub-group class="pb-5" v-if="$store.getters.isAdminUser || $store.getters.isAeonMakuhariUser">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>フロア・エリア表示</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isAuthenticated && $store.getters.isSetStoreAddress" link dense to="/parking_maps">
            <v-list-item-title>フロア・エリア検索</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group :value="true" no-action sub-group class="pb-5" v-if="$store.getters.isAdminUser || $store.getters.isAeonMakuhariUser">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>入出場履歴<span v-if="$store.getters.isAdminUser">(幕張新都心)</span></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isAuthenticated && $store.getters.isSetStoreAddress" link dense to="/makuharishintoshin-vehicle-informations">
            <v-list-item-title>入出場照会</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="true" no-action sub-group class="pb-5" v-if="$store.getters.isAdminUser || (($store.getters.isNomalUser || $store.getters.isSendaiAdmin || $store.getters.isSendaiInformation || $store.getters.isAeonMakuhariAdmin || $store.getters.isAeonMakuhariAccessible || $store.getters.isAeonMakuhariUser) && $store.getters.isAlertNotificationFlagged)">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>大型バスアラート機能</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isSetStoreAddress" link dense to="/new-alert-notifications">
            <v-list-item-title>通知先登録</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$store.getters.isSetStoreAddress" link dense to="/alert-notifications">
            <v-list-item-title>大型バスアラート通知先一覧</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group :value="true" no-action sub-group class="pb-5" v-if="$store.getters.isAdminUser || ($store.getters.isSendaiAdmin || $store.getters.isSendaiInformation)">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>駐車料金未払い車両アラート機能</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isSetStoreAddress" link dense to="/unpaid-alert-notifications">
            <v-list-item-title>一覧</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group :value="true" no-action sub-group class="pb-5" v-if="$store.getters.isAdminUser || ($store.getters.isSendaiAdmin || $store.getters.isSendaiInformation)">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>長期駐車車両アラート機能</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isSetStoreAddress" link dense to="/long-term-parking-alert-notifications">
            <v-list-item-title>一覧</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
          :value="true"
          no-action
          sub-group
          class="pb-5"
          v-if="$store.getters.isAdminUser || ($store.getters.isSendaiUser && $store.getters.isAccessibleVehicleFlagged) || ($store.getters.isRakeTownAdmin && $store.getters.isAccessibleVehicleFlagged) || (($store.getters.isAeonMakuhariAccessible || $store.getters.isAeonMakuhariUser) && $store.getters.isAccessibleVehicleFlagged)"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>障害者一般車両</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isSetStoreAddress" link dense to="/new-accessible-vehicles">
            <v-list-item-title>登録</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$store.getters.isSetStoreAddress" link dense to="/accessible-vehicles">
            <v-list-item-title>登録済車両一覧</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
          :value="true"
          no-action
          sub-group
          class="pb-5"
          v-if="$store.getters.isAdminUser || $store.getters.isSendaiUser"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>予約情報</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.getters.isSetStoreAddress" link dense to="/reservations">
            <v-list-item-title>予約情報一覧</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="true" v-if="$store.getters.isAdminUser || ($store.getters.isSendaiAdmin || $store.getters.isSendaiInformation)" no-action sub-group class="pb-5">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>履歴照会</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link dense to="/vehicle-informations">
            <v-list-item-title>入出庫照会</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/sales-inquiries">
            <v-list-item-title>売上照会</v-list-item-title>
          </v-list-item>
        </v-list-group>

      </v-list>
    </v-card>
    <!-- <BrowserVersionAlert/> -->
  </v-container>
</template>

<script>
import BrowserVersionAlert from '../../components/BrowserVersionAlert.vue'
import ReleaseNote from '../../components/ReleaseNote.vue'

export default {
  name: 'Home',
  components: { BrowserVersionAlert, ReleaseNote },
  data: () => ({
  }),
  methods: {
    fetchUserStatus () {
      this.$store.dispatch("fetchUserStatus")
    }
  },
  mounted() {
    this.fetchUserStatus()
  },
}
</script>

<style scoped>
</style>
