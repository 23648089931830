import Vue from 'vue'
import Router from 'vue-router'
import store from "./store"

import Home from './pages/home/index.vue'
import Dashboard from './pages/dashboard/index.vue'
import NewDeleteRequestHistory from './pages/deleteRequestHistories/new.vue'
import DeleteRequestHistories from './pages/deleteRequestHistories/index.vue'
import NewNoPaymentRequiredVehicles from './pages/noPaymentRequiredVehicles/new.vue'
import EditNoPaymentRequiredVehicles from './pages/noPaymentRequiredVehicles/edit.vue'
import OutputNoPaymentRequiredVehicles from './pages/noPaymentRequiredVehicles/output.vue'
import NoPaymentRequiredVehicles from './pages/noPaymentRequiredVehicles/index.vue'
import AlertVehicles from './pages/alertVehicles/index.vue'
import NewAlertVehicles from './pages/alertVehicles/new.vue'
import EditAlertVehicles from './pages/alertVehicles/edit.vue'
import NewGateControlVehicles from './pages/gateControlVehicles/new.vue'
import GateControlVehicles from './pages/gateControlVehicles/index.vue'
import EditGateControlVehicles from './pages/gateControlVehicles/edit.vue'
import AlertNotifications from './pages/alertNotifications/index.vue'
import NewAlertNotifications from './pages/alertNotifications/new.vue'
import EditAlertNotifications from './pages/alertNotifications/edit.vue'
import Reservations from './pages/reservations/index.vue'
import UnpaidAlertNotifications from './pages/unpaidAlertNotifications/index.vue'
import LongTermParkingAlertNotifications from './pages/longTermParkingAlertNotifications/index.vue'
import AccessibleVehicles from './pages/accessibleVehicles/index.vue'
import NewAccessibleVehicles from './pages/accessibleVehicles/new.vue'
import EditAccessibleVehicles from './pages/accessibleVehicles/edit.vue'
import SalesInquiries from './pages/salesInquiries/index.vue'
import RequestClientUsers from './pages/request_client_users/index.vue'
import RequestClientStores from './pages/request_client_stores/index.vue'
import DeleteRequestReasons from './pages/deleteRequestReasons/index.vue'
import VehicleInformations from './pages/vehicleInformations/index.vue'
import ShowVehicleInformations from './pages/vehicleInformations/show.vue'
import OutputVehicleInformations from './pages/vehicleInformations/output.vue'
import MakuharishintoshinVehicleInformations from './pages/vehicleInformations/makuharishintoshin.vue'
import ParkingMaps from './pages/parkingMaps/index.vue'
import Account from './pages/account/index.vue'
import Login from './pages/login/index.vue'
import NotFound from './components/NotFound.vue'
Vue.use(Router)

var router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_FRONT_BASE_PATH,
  routes: [
    { path: '/', name: 'home', component: Home },
    { path: '/dashboard', name: 'Dashboard', component: Dashboard },
    { path: '/new-delete-request', name: 'NewDeleteRequestHistory', component: NewDeleteRequestHistory },
    { path: '/reapply-delete-request/:id', name: 'reapplyDeleteRequestHistory', component: NewDeleteRequestHistory },
    { path: '/delete-request-histories', name: 'deleteRequestHistories', component: DeleteRequestHistories },
    { path: '/new-no-payment-required-vehicle', name: 'newNoPaymentRequiredVehicle', component: NewNoPaymentRequiredVehicles, beforeEnter: requireStoreAddress },
    { path: '/edit-no-payment-required-vehicle/:id', name: 'editNoPaymentRequiredVehicle', component: EditNoPaymentRequiredVehicles },
    { path: '/output-no-payment-required-vehicle', name: 'outputNoPaymentRequiredVehicle', component: OutputNoPaymentRequiredVehicles, beforeEnter: requireStoreAddress },
    { path: '/no-payment-required-vehicles', name: 'noPaymentRequiredVehicles', component: NoPaymentRequiredVehicles, beforeEnter: requireStoreAddress },
    { path: '/alert-vehicles', name: 'alertVehicles', component: AlertVehicles, beforeEnter: requireStoreAddress },
    { path: '/new-alert-vehicles', name: 'newAlertVehicles', component: NewAlertVehicles, beforeEnter: requireStoreAddress },
    { path: '/edit-alert-vehicles/:id', name: 'editAlertVehicles', component: EditAlertVehicles },
    { path: '/new-gate-control-vehicles', name: 'newGateControlVehicles', component: NewGateControlVehicles, beforeEnter: requireStoreAddress },
    { path: '/gate-control-vehicles', name: 'gateControlVehicles', component: GateControlVehicles, beforeEnter: requireStoreAddress },
    { path: '/edit-gate-control-vehicles', name: 'editGateControlVehicles', component: EditGateControlVehicles },
    { path: '/alert-notifications', name: 'alertNotifications', component: AlertNotifications, beforeEnter: requireStoreAddress },
    { path: '/edit-alert-notifications/:id', name: 'editAlertNotifications', component: EditAlertNotifications },
    { path: '/new-alert-notifications', name: 'newAlertNotifications', component: NewAlertNotifications, beforeEnter: requireStoreAddress },
    { path: '/reservations', name: 'reservations', component: Reservations, beforeEnter: requireStoreAddress },
    { path: '/unpaid-alert-notifications', name: 'unpaidAlertNotifications', component: UnpaidAlertNotifications, beforeEnter: requireStoreAddress },
    { path: '/long-term-parking-alert-notifications', name: 'longTermParkingAlertNotifications', component: LongTermParkingAlertNotifications, beforeEnter: requireStoreAddress },
    { path: '/accessible-vehicles', name: 'accessibleVehicles', component: AccessibleVehicles, beforeEnter: requireStoreAddress },
    { path: '/edit-accessible-vehicles/:id', name: 'editAccessibleVehicles', component: EditAccessibleVehicles },
    { path: '/new-accessible-vehicles', name: 'newAccessibleVehicles', component: NewAccessibleVehicles, beforeEnter: requireStoreAddress },
    { path: '/sales-inquiries', name: 'salesInquiries', component: SalesInquiries, beforeEnter: requireStoreAddress },
    { path: '/request_client_users', name: 'request_client_users', component: RequestClientUsers, beforeEnter: requireAdminRole },
    { path: '/request_client_stores', name: 'request_client_stores', component: RequestClientStores, beforeEnter: requireAdminRole },
    { path: '/request_client_stores/:request_client_store_name', name: 'request_client_store', component: RequestClientStores, beforeEnter: requireAdminRole },
    { path: '/delete-request-reasons', name: 'delete-request-reasons', component: DeleteRequestReasons, beforeEnter: requireAdminRole },
    { path: '/vehicle-informations', name: 'vehicleInformations', component: VehicleInformations },
    { path: '/show-vehicle-informations', name: 'showVehicleInformations', component: ShowVehicleInformations },
    { path: '/output-vehicle-informations', name: 'outputVehicleInformations', component: OutputVehicleInformations },
    { path: '/parking_maps', name: 'parkingMaps', component: ParkingMaps},
    { path: '/makuharishintoshin-vehicle-informations', name: 'makuharishintoshinVehicleInformations', component: MakuharishintoshinVehicleInformations },
    { path: '/account', name: 'account', component: Account, },
    { path: '/login', name: 'login', component: Login, meta: { isPublic: true } },
    { path: '/*', name: 'notFound', component: NotFound },
  ]
})

router.beforeEach((to, from, next) => {
  // if (to.path === from.path) {
  //   return next(false); // Stop navigation
  // }
  const restrictedPaths = [
    '/accessible-vehicles',
    '/edit-accessible-vehicles/:id',
    '/new-accessible-vehicles',
    '/edit-accessible-vehicles/:id',
    '/new-accessible-vehicles',
    '/vehicle-informations'
  ]
  // isPublic でない場合(=認証が必要な場合)、かつ、ログインしていない場合
  if (to.matched.some(record => !record.meta.isPublic) && !store.getters.isAuthenticated) {
    next({ path: '/login', query: { redirect: to.fullPath }})
  // isPublic で(=認証が不要な場合)、かつ、ログインしている場合
  } else if (to.matched.some(record => record.meta.isPublic) && store.getters.isAuthenticated) {
    next({ path: '/'})
  } else if (restrictedPaths.includes(to.path) && (!store.getters.isAdminUser && !store.getters.isSendaiUser && !store.getters.isRakeTownAdmin && !store.getters.isAeonMakuhariAccessible && !store.getters.isAeonMakuhariUser)) {
    next({ path: '/'})
  } else {
    next()
  }
})

function requireAdminRole(to, from, next) {
  if (store.getters.isAdminUser) {
    next()
  } else {
    next({ path: "/" })
  }
}

function requireStoreAddress(to, from, next) {
  if (store.getters.isSetStoreAddress) {
    next()
  } else {
    next({ path: "/" })
  }
}

export default router
